import React from 'react';
import App from './App';

import ReactDOM from 'react-dom';
import { StoreProvider } from './services/Context/storeContext';
import axios from "axios";
import Authentication from './services/authentication'
import { acquireAccessToken } from './services/msal';
import "./css/global/index.scss";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(async (config) => {
  const accessToken = await acquireAccessToken();
  config.headers.authorization = "Bearer " + accessToken;
  return config;
});

ReactDOM.render(
    <Authentication >
      <StoreProvider>
        <App />
      </StoreProvider>
    </Authentication>,
  document.getElementById('root')
);




