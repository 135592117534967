
import React, { useState, useEffect } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import "../../css/pages/badges/datatable.css"
import { Link, useNavigate } from "react-router-dom";


const DataTableStructor = ({ size, list }) => {
  const [clicked, setClicked] = useState(0)
  const [lastClicked, setLastClicked] = useState(false)
  const navigate = useNavigate();

  const selectedRow = (e) => {
    if (!clicked) {
      setClicked(clicked + 1)
      setLastClicked(e.id)
      setTimeout(() => { setClicked(0); }, 500)
    } else {
      if (lastClicked === e.id) {
        navigate("/badges/" + e.id);
      } else {
        setClicked(0)
      }
    }
  }

  const [customers, setCustomers] = useState(null);
  const [filters1, setFilters1] = useState({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    'creator': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    'representative': { value: null, matchMode: FilterMatchMode.IN },
    'available': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
  });


  const filtersMap = {
    'filters1': { value: filters1, callback: setFilters1 }
  };

  useEffect(() => {
    setCustomers(list)
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const countryBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="image-text">{rowData.creator}</span>
      </React.Fragment>
    );
  }

  const representativeBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="image-text">{rowData.created.substring(0, 10)}</span>
      </React.Fragment>
    );
  }

  const statusBodyTemplate = (rowData) => {
    return <span className={`customer-badge status-${rowData.available}`}>{rowData.available}</span>;
  }

  //CHANGE TO BADGE OF ITEM, FETCH FROM BUCKET.
  const designBodyTemplate = (option) => {
    /*
    return (
      <div className="p-multiselect-representative-option">
        <Badge badge={option} />
      </div>
    );*/
  }
  const onGlobalFilterChange = (event, filtersKey) => {
    const value = event.target.value;
    let filters = { ...filtersMap[filtersKey].value };
    filters['global'].value = value;

    filtersMap[filtersKey].callback(filters);
  }

  const renderHeader = (filtersKey) => {
    const filters = filtersMap[`${filtersKey}`].value;
    const value = filters['global'] ? filters['global'].value : '';

    return (
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e, filtersKey)} placeholder="Global Search" />
      </span>
    );
  }


  const createLink = (option) => {
    return (
      <Link to={option.id} className={`arrowButton`}>&rarr;</Link>
    )
  }


  const header1 = renderHeader('filters1');

  return (
    <div>
      <div className="card" >
        <DataTable value={customers} paginator rows={8} header={header1} filters={filters1} onFilter={(e) => setFilters1(e.filters)}
          selectionMode="single" dataKey="id" responsiveLayout={window.outerWidth < 1200 ? "stack" : "scroll"}
          stateStorage="session" stateKey="dt-state-demo-session" emptyMessage="No badges found" onSelectionChange={e => selectedRow(e.value)}>
          <Column field="name" header="Badge Name" sortable ></Column>
          <Column field="creator" header="Created By" body={countryBodyTemplate} sortable ></Column>
          <Column style={{ textAling: "center" }} field="created" header="Created At" body={representativeBodyTemplate} sortable ></Column>
          <Column field="available" header="Status" body={statusBodyTemplate} sortable></Column>
          <Column style={{ width: "0rem" }} field="design" header="Design" body={designBodyTemplate}></Column>
          {size.windowWidth > 1200 && <Column style={{ width: "6rem", padding: "0px", margin: "auto" }} body={createLink}></Column>}
        </DataTable>
      </div>
    </div >
  );
}

export default DataTableStructor
