import styles from "../badge.module.scss"
import Badge from "../../BadgeStructor"
import json from "./background.json"

const Background = ({ setBadge, badge }) => {

  return (
    <div className={styles.wrapper}>
      {json.map((i) => {
        return (
          <button key={i.name} className={styles.button} onClick={() => setBadge({ ...badge, badgeStructor: { ...badge.badgeStructor, background: i.name } })}>
            <Badge badge={{ ...badge, badgeStructor: { ...badge.badgeStructor, background: i.name } }} />
          </button>)
      })}
    </div >
  )
}
export default Background