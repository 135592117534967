import Template from "./badges/templates/Template.jsx"
import Symbols from "./badges/symbols/Symbols.jsx"
import Background from "./badges/backgrounds/Background.jsx"
import Colors from "./badges/colors/Colors.jsx"
import TextInput from "./badges/text/TextInput.jsx"
import Rotation from "./badges/text/Rotation.jsx"


const Design = ({ setBadge, badge }) => {

  return (
    <>
      <h2 className="fSizeB">Background</h2>
      <Background setBadge={setBadge} badge={badge} />
      <h2 className="fSizeB">Symbol</h2>
      <Symbols setBadge={setBadge} badge={badge} />
      <h2 className="fSizeB">Text</h2>
      <Template setBadge={setBadge} badge={{ ...badge, badgeStructor: { ...badge.badgeStructor, text: { ...badge.badgeStructor.text, text: "Example Title", rotation: 345 } } }} />
      {badge.badgeStructor.text.template &&
        <div style={{ margin: "1rem 0px" }}>
          <TextInput setBadge={setBadge} badge={badge} />
          <h3 className="fSizeS">Rotatin of Text</h3>
          <Rotation setBadge={setBadge} badge={badge} />
        </div>
      }
      <h2 className="fSizeB">Color</h2>
      <Colors setBadge={setBadge} badge={badge} />
    </>
  )

}

export default Design