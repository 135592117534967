import { Circle, Wave, Star } from "./badges/backgrounds/assets"
import { Fish, Heart, Bike, Egg, Electronic } from "./badges/symbols/assets"

const Badge = ({ badge, badgePreview }) => {
  return (
    <svg id={badgePreview} width="100%" height="100%" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g >
        <ellipse cx="125" cy="125" rx="125" ry="125" fill={"white"} />
        <ellipse cx="125" cy="125" rx="122" ry="122" fill={badge.badgeStructor.colors.primaryColor ? badge.badgeStructor.colors.primaryColor : "#C4C4C4"} />
      </g>
      {badge.badgeStructor.background && BackgroundSelector(badge)}
      {badge.badgeStructor.symbol && SymbolSelector(badge)}
      {badge.badgeStructor.text.template && TextBuilder(badge)}
      <defs>
        <clipPath id="clip0_14_325">
          <rect width="250" height="250" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Badge

const BackgroundSelector = (obj) => {
  if (obj.badgeStructor.background === "Wave") {
    return (<Wave color={obj.badgeStructor.colors.secondaryColor} />)
  } else if (obj.badgeStructor.background === "Circle") {
    return (<Circle color={obj.badgeStructor.colors.secondaryColor} />)
  } else if (obj.badgeStructor.background === "Star") {
    return (<Star color={obj.badgeStructor.colors.secondaryColor} />)
  }
}

const SymbolSelector = (obj) => {
  if (obj.badgeStructor.symbol === "Fish") {
    return (<Fish color={obj.badgeStructor.colors.symbolColor} />)
  } else if (obj.badgeStructor.symbol === "Heart") {
    return (<Heart color={obj.badgeStructor.colors.symbolColor} />)
  } else if (obj.badgeStructor.symbol === "Bike") {
    return (<Bike color={obj.badgeStructor.colors.symbolColor} />)
  } else if (obj.badgeStructor.symbol === "Egg") {
    return (<Egg color={obj.badgeStructor.colors.symbolColor} />)
  } else if (obj.badgeStructor.symbol === "Electronic") {
    return (<Electronic color={obj.badgeStructor.colors.symbolColor} />)
  }
}

const TextBuilder = (badge) => {
  if (badge.badgeStructor.text.template === "text_round") {
    return (
      <>
        <path
          id="myTextPath"
          d="
        M 0, 125
        a 125,125 0 1,0 250,0 
        a 125,125 0 1,0 -250,0
        "
          fill="none" stroke="darkblue" strokeWidth="0" ></path>
        <text style={{ fontSize: `${badge.badgeStructor.text.fontsize}px`, letterSpacing: "10px", transform: `rotate(${badge.badgeStructor.text.rotation}deg)`, transformOrigin: "center" }} fill={badge.badgeStructor.colors.textColor} strokeWidth="0">
          <textPath font-weight={"bold"} href="#myTextPath"  ><tspan dy="-10">{badge.badgeStructor.text.title}</tspan></textPath>
        </text>
      </>
    )
  } else {
    return (<></>)
  }
}


