import { types } from './reducers';
import axios from 'axios';
import {useMsal } from '@azure/msal-react';

export const useActions = (state, dispatch) => {
  const {accounts} = useMsal()
  const fetchUserData = async () => {
    try {
      const user = (await axios.get(`${process.env.REACT_APP_API_URL}/user`)).data;
      sessionStorage.setItem('userOid', accounts[0].idTokenClaims.oid);
      
      dispatch({ type: types.USER_DATA, payload: {...user} });
    } catch(error) {
      console.error(error);
      if(error.message === "no_tokens_found: No refresh token found in the cache. Please sign-in.") {
        localStorage.clear();
        window.location.reload();
      }
    }
  };

  const updateContext = async (updateState) => {
    dispatch({ type: types.USER_DATA, payload: updateState })
  }

  return {
    fetchUserData,
    updateContext
  };
};


