import styles from "../badge.module.scss"
const Colors = ({ badge, setBadge }) => {


  const colorPicker = (name, type) => {
    return (
      <div className={styles.inputColors}>
        <input type="color" name={type}
          onChange={(e) => {
            setBadge({
              ...badge,
              badgeStructor: { ...badge.badgeStructor, colors: { ...badge.badgeStructor.colors, [type]: e.target.value } }
            })
          }} value={badge.badgeStructor.colors[type]} />
        <label className="fSizeSM" htmlFor={type}>{name}</label>
      </div>
    )
  }

  return (
    <>
      <div className={styles.flexWrapper}>
        {colorPicker("Primary Color", "primaryColor")}
        {colorPicker("Secondary Color", "secondaryColor")}
      </div>
      <div className={styles.flexWrapper}>
        {colorPicker("Text Color", "textColor")}
        {colorPicker("Symbol Color", "symbolColor")}
      </div>
    </>
  )
}

export default Colors