import styles from "../../css/global/component/personalstats.module.scss"

const PersonalStats = () => {

  return (
    <div className={styles.mainWrapper}>

    </div>
  )
}

export default PersonalStats