import styles from "../css/pages/certificate/structor.module.scss"
import React, { useState, useEffect } from "react"
import {
  useParams
} from "react-router-dom";
import { IngkaLogo } from "../components/global/svg/index.js"
import { Link } from "react-router-dom";
import axios from "axios"
import Badge from "../components/pages/create/wizard/BadgeStructor";


const Certificate = ({ userData }) => {
  const { id, user } = useParams();
  const [cerificate, setCertificate] = useState()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState()
  useEffect(() => {

    const fetchData = async () => {
      if (!user) {
        await axios.get(`${process.env.REACT_APP_API_URL}/badge/id/${id}`)
          .then(response => {
            setMessage(false)
            setCertificate(response.data)
            setLoading(true)
          })
      } else {
        await axios.get(`${process.env.REACT_APP_API_URL}/certificate/${id}/${user}`)
          .then(response => {
            console.log(response)
            if (response.status === 200) {
              setCertificate(response.data)
              setLoading(true)
              if (user === userData.userPrincipalName) {
                setMessage(`You have recieved a badge from ${response.data.creator}`)
              } else {
                setMessage(`This is a certifcation that the owner ${user} has collected this badge`)
              }
            }
          }).catch(error => {
            console.log(error)
            setMessage(`The user ${user} dosen´t own this badge`)
            setLoading(true)
          })
      }
    }

    if (!loading) {
      fetchData()
        .catch(console.error);
    }
  }, [])

  const downloadSVG = () => {

  }

  const shareBadge = () => {

  }

  return (
    <>
      {loading ?
        cerificate ?
          <div className={styles.mainWrapper}>
            <div className={styles.logo}>
              <IngkaLogo c1={"#3E3E3E"} />
              <div>
                <Link to={`/badges/${cerificate.id}`} style={{ textDecoration: "none", fontSize: "38px" }}>{"<"}</Link>
              </div>
            </div>
            <div className={styles.leftWrapper}>
              <h1 className={`${styles.message} fSizeM fNormalWeight`}>{message}</h1>
              <div className={styles.contentWrapper}>
                <h1 className={styles.title}>{cerificate.name}</h1>
                <p className={styles.description}>{cerificate.desc}</p>
                <div className={styles.informationWrapper}>
                  <div className={styles.flex}>
                    <img className={styles.infoLogo} src={"/assets/symbols/clock.svg"} alt={"timestamp"} />
                    <p><span style={{ fontWeight: "bold" }}>Created at: </span> {cerificate.created.substring(0, 10)}</p>
                  </div>
                  <div className={styles.flex}>
                    <img className={styles.infoLogo} src={"/assets/symbols/profile.svg"} alt={"Creator"} />
                    <p><span style={{ fontWeight: "bold" }}>Created by: </span> {cerificate.creator}</p>
                  </div>
                </div>
                <div className={styles.buttonWrapper}>
                  <Link to="/" className={styles.learnMoreButton}>Learn More</Link>
                  <div className={styles.flex}>
                    <button onClick={() => downloadSVG()} className={styles.buttonLogoWrapper}><img className={styles.buttonLogo} src={"/assets/symbols/download.svg"} alt={"download"} /></button>
                    <button onClick={() => shareBadge()} className={styles.buttonLogoWrapper}><img className={styles.buttonLogo} src={"/assets/symbols/share.svg"} alt={"share"} /></button>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.rightWrapper}>
              <div className={styles.imageWrapper}>
                <Badge badge={cerificate} />
              </div>
            </div>
          </div >
          : <div>{message}</div>
        : <div>loading</div>}
    </>
  )
}

export default Certificate

