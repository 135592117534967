import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/global/Navbar";
import Header from "./components/global/Header";
import styles from "./css/global/structor.module.scss";
import { Dashboard, Create, Home, Profile, Certificate, Editor } from "./view";
import { Search, BadgeView } from "./view/Badges"
import React, { useState, useEffect, useContext } from "react"
import { StoreContext } from "./services/Context"
import { acquireAccessToken } from './services/msal';
import { ProgressBar } from 'primereact/progressbar';

function App() {
  const { actions, state } = useContext(StoreContext);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    (async () => {
      //GET BEARER TOKEN
      const accessToken = await acquireAccessToken();
      console.log(accessToken)
    })()
  }, [])

  useEffect(() => {
    actions.fetchUserData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!Object.keys(state.userData).length) return;
    setLoading(false);
  }, [state.userData]);

  return (
    <>
      {!loading ?
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/dashboard" element={<AppStructor Component={<Dashboard />} page={"/dashboard"} />} />
            <Route path="/profile" element={<AppStructor Component={<Profile userData={state.userData} />} page={"/profile"} />} />
            <Route path="/badges" element={<AppStructor Component={<Search />} page={"/badges"} />} />
            <Route path="/badges/:id" element={<AppStructor Component={<BadgeView />} page={"/badges"} />} />
            <Route path="/build/create" element={<AppStructor Component={<Create />} page={"/create"} />} />
            <Route path="/build/edit/:id" element={<AppStructor Component={<Editor />} page={"/create"} />} />
            <Route path="/certificate/:id/:user" element={<Certificate userData={state.userData} />} />
            <Route path="/certificate/:id" element={<Certificate userData={state.userData} />} />
          </Routes>
        </BrowserRouter>
        :
        <div style={{ height: "100vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <ProgressBar mode="indeterminate" style={{ height: '6px', width: "80%" }} color="#FCE034" />
        </div>}
    </>
  );
}

export default App;

const AppStructor = ({ Component, page }) => {

  return (
    <div className={styles.mainWrapper}>
      {window.outerWidth < 1200 ?
        <>
          <Header navbar={true} page={page} />
        </>
        :
        <>
          <Header />
          <Navbar page={page} />
        </>
      }
      <div className={styles.wrapper}>
        {Component}
      </div>
    </div>)
}


