const CheckBox = ({ title, desc, obj }) => {
  return (
    <div style={{ flexGrow: 1, margin: "0px 1rem" }}>
      {title && <h3 className="fSizeSM">{title}</h3>}
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 3, flexBasis: "0px" }}>
          <p style={{ margin: "0px" }}>{desc}</p>
        </div>
        <div style={{
          flexGrow: 1, flexBasis: "0px"
        }}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "0.5rem" }}>
            {obj}
          </div>
        </div>
      </div>
    </div >
  )
}
export default CheckBox