import styles from "../css/pages/dashboard/structor.module.scss"
import SummaryContainer from "../components/global/SummaryContainer"
import PersonalStats from "../components/global/PersonalStats"
import React, { useState, useEffect } from "react"
import axios from "axios"


const Dashboard = () => {
  const [loading, setLoading] = useState(false)
  const [global, setGlobal] = useState()
  useEffect(() => {
    if (loading) return
    const fetchData = async () => {
      const badgeResponse = await axios.get(`${process.env.REACT_APP_API_URL}/badge/global`)
      if (badgeResponse.status === 200) {
        setGlobal(badgeResponse.data)
        setLoading(true)
      }
    }
    fetchData()
      .catch(console.error);
  }, [loading])

  return (
    <>
      {loading ?
        <div className={styles.mainWrapper}>
          <div className={styles.leftWrapper}>
            <div className={styles.wrapper}>
              <SummaryContainer title={"Total created"} number={global.created} svg={"/assets/symbols/badge.svg"} alt={"badge"} />
              <SummaryContainer title={"Total collected"} number={global.collected || 0} svg={"/assets/symbols/handheart.svg"} alt={"hand holdinga heart"} />
            </div>
            <PersonalStats />
          </div>
          <div className={styles.rightWrapper}>
          </div>
        </div>
        : <div></div>
      }
    </>
  )
}

export default Dashboard