import styles from "../../css/global/component/header.module.scss"
import React, { useState } from "react"
import { IngkaLogo, HomeLogo, ProfileLogo, BadgeLogo, CreateLogo } from "./svg"
import { Link } from "react-router-dom";

const Header = ({ navbar, page }) => {

  return (
    <>
      <div className={styles.headerWrapper} >
        {navbar &&
          <ul className={styles.listWrapper}>
            <li><Link to="/dashboard"><HomeLogo c1={page === "/dashboard" ? "#ffffff" : "#3E3E3E"} /></Link></li>
            <li><Link to="/profile"><ProfileLogo c1={page === "/profile" ? "#ffffff" : "#3E3E3E"} /></Link></li>
            <li><Link to="/badges"><BadgeLogo c1={page === "/badges" ? "#ffffff" : "#3E3E3E"} /></Link></li>
            <li><Link to="/create"><CreateLogo c1={page === "/create" ? "#ffffff" : "#3E3E3E"} /></Link></li>
          </ul>
        }
      </div>
    </>
  )
}

export default Header