import styles from "../../css/global/component/navbar.module.scss"
import { IngkaLogo, HomeLogo, ProfileLogo, BadgeLogo, CreateLogo } from "./svg"
import { Link } from "react-router-dom";

const Navbar = ({ page }) => {
  return (
    <div className={styles.navWrapper}>
      <div className={styles.logoWrapper}>
        <IngkaLogo c1={"#ffffff"} />
      </div>
      <nav tabIndex="0" className={styles.navWrapper}>
        <ul>
          <li><Link to="/dashboard"><HomeLogo c1={page === "/dashboard" ? "#FCE034" : "#ffffff"} /></Link></li>
          <li><Link to="/profile"><ProfileLogo c1={page === "/profile" ? "#FCE034" : "#ffffff"} /></Link></li>
          <li><Link to="/badges"><BadgeLogo c1={page === "/badges" ? "#FCE034" : "#ffffff"} /></Link></li>
          <li><Link to="/build/create"><CreateLogo c1={page === "/create" ? "#FCE034" : "#ffffff"} /></Link></li>
        </ul>
      </nav>
    </div >
  )
}

export default Navbar