const Star = ({ color }) => {
  return (
    <svg width="250" height="250" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M60 35L61.5716 39.8369H66.6574L62.5429 42.8262L64.1145 47.6631L60 44.6738L55.8855 47.6631L57.4571 42.8262L53.3426 39.8369H58.4284L60 35Z" fill={color} />
      <path d="M105 8L108.368 18.3647H119.266L110.449 24.7705L113.817 35.1353L105 28.7295L96.1832 35.1353L99.5509 24.7705L90.7342 18.3647H101.632L105 8Z" fill={color} />
      <path d="M151 30L154.817 41.7467L167.168 41.7467L157.176 49.0066L160.992 60.7533L151 53.4934L141.008 60.7533L144.824 49.0066L134.832 41.7467L147.183 41.7467L151 30Z" fill={color} />
      <path d="M199 65L201.021 58.7812H207.56L202.269 54.9377L204.29 48.7188L199 52.5623L193.71 48.7188L195.731 54.9377L190.44 58.7812H196.979L199 65Z" fill={color} />
      <path d="M175 80L177.694 88.2918H186.413L179.359 93.4164L182.053 101.708L175 96.5836L167.947 101.708L170.641 93.4164L163.587 88.2918H172.306L175 80Z" fill={color} />
      <path d="M113 63L114.796 68.5279H120.608L115.906 71.9443L117.702 77.4721L113 74.0557L108.298 77.4721L110.094 71.9443L105.392 68.5279H111.204L113 63Z" fill={color} />
      <path d="M75 112L76.7961 117.528H82.6085L77.9062 120.944L79.7023 126.472L75 123.056L70.2977 126.472L72.0938 120.944L67.3915 117.528H73.2039L75 112Z" fill={color} />
      <path d="M33 68L36.5922 79.0557H48.2169L38.8123 85.8885L42.4046 96.9443L33 90.1115L23.5954 96.9443L27.1877 85.8885L17.7831 79.0557H29.4078L33 68Z" fill={color} />
      <path d="M78 78L79.3471 82.1459H83.7063L80.1796 84.7082L81.5267 88.8541L78 86.2918L74.4733 88.8541L75.8204 84.7082L72.2937 82.1459H76.6529L78 78Z" fill={color} />
      <path d="M125 104L125.898 106.764H128.804L126.453 108.472L127.351 111.236L125 109.528L122.649 111.236L123.547 108.472L121.196 106.764H124.102L125 104Z" fill={color} />
    </svg>

  )
}

export default Star