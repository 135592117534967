import React, { useState, useContext } from "react"
import { useNavigate } from "react-router-dom"
import styles from "../../../css/pages/create/wizard/wizard.module.scss"
import { Design, Information, Preview } from "./wizard";
import axios from "axios"

import { StoreContext } from "../../../services/Context"


const WizardController = ({ badge, setBadge, id }) => {
  const [wizardView, setWizardView] = useState(0)
  const [agree, setAgree] = useState(false)
  const navigation = useNavigate()

  const { actions, state: { userData } } = useContext(StoreContext);

  const changeView = async (step) => {
    const maxStep = 2;
    if (wizardView + step <= maxStep && wizardView + step >= 0) {
      setWizardView(wizardView + step)
    } else {
      if (agree) {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/badge/create`, badge)
        if (response.status === 200) {
          actions.updateContext({
            ...userData,
            badgeCreated: [...userData.badgeCreated, badge],
          });
          navigation(`/badges/${response.data.id}`)
        }
      }
    }
  }

  const nextStep = (step) => {
    if (step === 1) {
      if (!badge.available || !badge.name || !badge.desc) {
        return true
      }
    } else if (step === 2) {
      return !agree
    }
  }

  return (
    <div className={styles.wrapper}>
      {wizardView === 0 && <Design setBadge={setBadge} badge={badge} />}
      {wizardView === 1 && <Information setBadge={setBadge} badge={badge} />}
      {wizardView === 2 && <Preview setBadge={setBadge} badge={badge} setAgree={setAgree} agree={agree} id={id} />}
      <div className={styles.stepWrapper}>
        {wizardView !== 0 && !badge.uploadedImage && <button className={styles.prevStep} onClick={() => { changeView(-1) }}>Previous Step</button>}
        <button disabled={nextStep(wizardView)}
          className={`${styles.nextStep} ${nextStep(wizardView) ? styles.disabled : styles.notdisabled}`} onClick={() => { changeView(1) }} > {wizardView === 2 ? "Create Badge" : "Next Step"} </button>
      </div>
    </div>
  )
}

export default WizardController