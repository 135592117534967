import styles from "../../css/pages/badges/structor.module.scss"
import { DataTableStructor } from "./index.js";
import React, { useEffect, useState } from "react"
import axios from "axios"

const Search = () => {
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState()

  const [size, setSize] = useState({ windowWidth: window.outerWidth, windowHeight: window.outerHeight });
  useEffect(() => {
    const handleResize = () => {
      setSize({ windowWidth: window.outerWidth, windowHeight: window.outerHeight });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, []);

  useEffect(() => {
    if (loading) return
    const fetchData = async () => {
      const badgeResponse = await axios.get(`${process.env.REACT_APP_API_URL}/badge/all`)
      if (badgeResponse.status === 200) {
        setList(badgeResponse.data)
        setLoading(true)
      }
    }

    // call the function
    fetchData()
      .catch(console.error);
  }, [loading])


  return (
    <div className={styles.mainGridWrapper}>
      <div className={styles.sectionWrapper}>
        <div className={styles.searchWrapper}>
          {loading &&
            <DataTableStructor size={size} list={list} />
          }
        </div>
      </div>
    </div>
  )
}

export default Search