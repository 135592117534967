import styles from "../../css/pages/badges/sendbadge.module.scss"
import React, { useState } from "react"
import { Chips } from 'primereact/chips';
import axios from "axios";
import * as EmailValidator from 'email-validator';

const SendBadge = ({ badgeID, setBadge, badge }) => {
  const [emails, setEmails] = useState([]);
  const [response, setResponse] = useState([])
  const [loading, setLoading] = useState(false)

  const sendBadgeToUsers = () => {

    setLoading(true)
    Promise.all(emails.map(async email => {
      let response
      try {
        response = await axios.post(`${process.env.REACT_APP_API_URL}/badge/send`, {
          id: badgeID,
          recivier: email
        })
      } catch (err) {
        return { user: email, status: err.response.status };
      }
      return { user: email, status: response.status, id: badgeID }
    })).then(results => {
      setResponse(results)
      const okResponse = results.filter(resp => resp.status === 200)
      setBadge({ ...badge, badgeCollected: [...badge.badgeCollected, ...okResponse] })
      setLoading(false)
    })
  }

  const removeEmail = (inputMail) => {
    let arr = emails.filter(mail => mail !== inputMail)
    setEmails(arr)
  }

  const ResponseMessage = (item) => {
    if (response.filter(resp => resp.user === item).length > 0) {
      let status = response.filter(resp => resp.user === item)[0].status
      if (status === 200) {
        return <span style={{ color: "green" }}> - (Sent) </span>
      } else if (status === 404) {
        return <span style={{ color: "red" }}> - (Email is not a INGKA mail) </span>
      } else if (status === 409) {
        return <span style={{ color: "red" }}> - (Already Have this Badge) </span>
      } else if (status === 500) {
        return <span style={{ color: "red" }}> - (Internal Server Error) </span>
      }

    } else {
      if (!EmailValidator.validate(item)) {
        return <span style={{ color: "red" }}> - (Not a valid email) </span>
      }
    }
  }

  const customChip = (item) => {
    return (
      <div className={styles.chipWrapper}>
        <span>{item} </span>
        {ResponseMessage(item)}
        <i className="pi pi-user-plus" style={{ fontSize: '14px' }}></i>
        {loading ? <div>loading</div> : <button onClick={() => { removeEmail(item) }} className={styles.removeButton}>X</button>}
      </div>
    );
  }

  return (
    <div className={styles.mainWrapper}>
      <h1 className={`${styles.title} fSizeB`}>Send Badge</h1>
      <p className={styles.paragraph}>
        A badge can only be sent by the creator of the badge, and only be sent to a coworker working at Ingka. <br /><br /> Insert the coworkers you want to send this badge to:
      </p>
      <Chips value={emails}
        onChange={(e) => setEmails(e.value)} max={50} itemTemplate={customChip} addOnPaste="true" separator="," />
      <button onClick={() => { sendBadgeToUsers() }} className={styles.sendButton}>Send Badges</button>
    </div >
  )
}

export default SendBadge;

