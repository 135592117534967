import styles from "../css/pages/home/structor.module.scss"
import pageData from "../content/home/info.json"
import React, { useState } from "react"
import Navbar from "../components/pages/home/Navbar"
import { IngkaLogo } from "../components/global/svg/index.js"
import { Link } from "react-router-dom";

const Home = () => {
  const [pageNumber, setPageNumber] = useState(0)

  const changePage = (nr) => {
    if (nr + pageNumber > 3) return 0
    if (nr + pageNumber < 0) return 3
    return nr + pageNumber
  }

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.logo}>
        <IngkaLogo c1={"#3E3E3E"} />
      </div>
      <div className={styles.leftWrapper}>
        <div className={styles.contentWrapper}>
          <h1 className={styles.title}>{pageData[pageNumber].title}</h1>
          <div style={{ maxHeight: "140px", overflowY: "scroll" }}>
            {pageData[pageNumber].text.map((text, index) => {
              return <p key={index}>{text}</p>
            })}
            <a href="https://beta.openai.com/playground" target={"_blank"}>This was written by a robot</a>
          </div>
          <div className={styles.buttonWrapper}>
            {pageData[pageNumber].dashboard && <Link to="/dashboard" className={`${styles.dashboardButton} fSizeM`}>Dashboard</Link>}
            {pageData[pageNumber].learnmore && <button onClick={() => setPageNumber(changePage(1))} className={`${styles.learnMoreButton} fSizeM`}>Learn More</button>}
          </div>
        </div>
        <div className={styles.pageNrWrapper}>
          <h3>{pageNumber + 1}</h3>
          <hr />
          <h2 >4</h2>
        </div>
      </div>
      <div className={styles.rightWrapper}>
        <Navbar page={"/"} />
        <div className={styles.imageWrapper}>
          <img src={pageData[pageNumber].image} alt={pageData[pageNumber].alt} />
        </div>
      </div>
      <div className={styles.pageNavigationWrapper}>
        <button className={`${styles.arrowWrapper} ${styles.leftArrow}`}
          onClick={(e) => setPageNumber(changePage(-1))}>
          &larr;
        </button>
        <button className={`${styles.arrowWrapper} ${styles.rightArrow}`}
          onClick={(e) => setPageNumber(changePage(1))}>
          &rarr;
        </button>
      </div>

    </div >
  )
}

export default Home

