import React, { useState } from "react"
import styles from "../../css/pages/create/wizard/main.module.scss"
import { Preview, WizardController } from "../../components/pages/create"
import { useNavigate } from "react-router-dom"

const Create = () => {
  const [badge, setBadge] = useState({
    name: "",
    desc: "",
    available: "",
    badgeStructor: {
      background: "",
      symbol: "",
      text: {
        template: "",
        title: "Example Text",
        rotation: 0,
        fontsize: 32
      },
      colors: {
        primaryColor: "#C4C4C4",
        secondaryColor: "#383838",
        textColor: "#383838",
        symbolColor: "#383838",
      }
    }
  })

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.containers} ${styles.cWizard}`}>
        <WizardController badge={badge} setBadge={setBadge} />
      </div>
      <div className={`${styles.containers} ${styles.cPreview}`}>
        <Preview badge={badge} />
      </div>
    </div>
  )
}

export default Create