import styles from "../css/pages/profile/structor.module.scss"
import SummaryContainer from "../components/global/SummaryContainer"
import BadgeScrollBar from "../components/global/BadgeScrollBar"

const Profile = ({ userData }) => {
  return (
    <div className={styles.mainWrapper}>
      <div className={styles.leftWrapper}>
        <div className={styles.profileWrapper}>
          <div style={{ background: "#3E3E3E", marginTop: "50px", width: "80px", height: "80px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "100%" }}>
            <img src={"/assets/symbols/profileWhite.svg"} alt={"profile"} style={{ width: "50px" }}></img>
          </div>
          <h2 style={{ margin: "5px 0px 0px 0px" }} className="fSizeM">{userData.userPrincipalName}</h2>
          <h3 style={{ margin: "5px" }} className="fSizeS">{userData.officeLocation}</h3>
          <BadgeScrollBar title="Your Created Badges" badges={userData.badgeCreated} />
          <BadgeScrollBar title="Your Collected Badges" badges={userData.badgeCollected} />
        </div>
      </div>
      <div className={styles.rightWrapper}>
        <div className={styles.flex}>
          <SummaryContainer title={"You have created"} number={userData.badgeCreated.length} svg={"/assets/symbols/badge.svg"} alt={"badge"} />
          <SummaryContainer title={"You have collected"} number={userData.badgeCollected.length} svg={"/assets/symbols/handheart.svg"} alt={"hand holdinga heart"} />
        </div>
      </div>
    </div>
  )
}

export default Profile