import { Link } from "react-router-dom";
import styles from "../../../css/pages/home/navbar.module.scss"
const Navbar = ({ page }) => {

  return (
    <div className={styles.navbarWrapper}>
      <Link to="/" className={page === "/" ? styles.onPage : undefined}>Home</Link>
      <Link to="/about" className={page === "/about" ? styles.onPage : undefined}>About</Link>
      <Link to="/dashboard">Dashboard</Link>
    </div >
  )
}

export default Navbar