import Badge from "./wizard/BadgeStructor.jsx"

const Preview = ({ badge }) => {

  return (
    <>
      <h1 className="fSizeB fCenter">Preview</h1>
      <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <div style={{ height: "400px" }}>
          {badge.uploadedImage ?
            <img alt="not fount" height={"100%"} style={{ maxWidth: "250px" }} src={URL.createObjectURL(badge.uploadedImage)} />
            : <Badge badgePreview={"badgePreviewSVG"} badge={badge} />
          }
        </div>
      </div>
    </>
  )
}

export default Preview