const Circle = ({ color }) => {

  return (
    <svg width="250" height="250" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_14_325" maskUnits="userSpaceOnUse" x="0" y="0" width="250" height="250">
        <circle cx="125" cy="125" r="122" fill="white" />
      </mask>
      <g mask="url(#mask0_14_325)">
        <path d="M64.0949 155.659C20.8766 138.079 -7.01997 166.647 -15.566 183.129C-23.8068 194.421 -17.9466 227.446 71.4201 269.199C183.128 321.391 347.944 208.766 284.765 180.382C221.585 151.997 193.201 183.129 157.49 192.285C121.78 201.441 118.118 177.635 64.0949 155.659Z" fill={color} />
      </g>
    </svg>
  )
}

export default Circle
