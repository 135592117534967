const BadgeLogo = ({ c1, c2, c3 }) => {

  return (
    <svg width="38" height="60" viewBox="0 0 38 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M29.0825 34.426C33.9458 31.1299 37.1476 25.5165 37.1476 19.1461C37.1476 8.99812 29.0226 0.771545 19 0.771545C8.97731 0.771545 0.852295 8.99812 0.852295 19.1461C0.852295 25.5174 4.05506 31.1312 8.91923 34.4272L2.48891 56.5652L7.06817 59.9773L19.0012 51.9225L30.9342 59.9773L35.5134 56.5652L29.0825 34.426ZM31.0984 19.1461C31.0984 25.9113 25.6816 31.3957 19 31.3957C12.3183 31.3957 6.90152 25.9113 6.90152 19.1461C6.90152 12.3809 12.3183 6.89639 19 6.89639C25.6816 6.89639 31.0984 12.3809 31.0984 19.1461ZM23.51 36.9488C22.0362 37.3296 20.521 37.5217 19 37.5206C17.4796 37.5217 15.9652 37.3297 14.4921 36.9491L10.6418 50.2036L19.0012 44.5614L27.3603 50.2036L23.51 36.9488Z" fill={c1} />
    </svg>

  )
}

export default BadgeLogo