import styles from "../../css/global/component/badgecreatorotherbadges.module.scss"
import Badge from "../pages/create/wizard/BadgeStructor"
import { Link } from "react-router-dom";
const BadgeScrollBar = ({ title, badges }) => {
  return (
    <div className={styles.mainWrapper}>
      <div className={styles.wrapper}>
        <h1 className={`${styles.title} fSizeB fNormalWeight`}>{title}</h1>
        <div className={styles.flex}>
          <div className={styles.imgWrapper}>
            {badges.map((badge, index) => {
              return <Link key={badge + index} style={{ height: "100%" }} to={`/badges/${badge.id}`}><Badge badge={badge} /></Link>
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BadgeScrollBar