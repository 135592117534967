import styles from "../../css/global/component/summarycontainer.module.scss"

const SummaryContainer = ({ title, number, svg, alt }) => {
  return (
    <div className={styles.mainWrapper}>
      <div className={styles.wrapper}>
        <div>
          <h1 className={`${styles.title} fSizeB`}>{title}</h1>
          <h2 className={`${styles.number} fSizeB`}>{number}</h2>
        </div>
        <div className={styles.imageWrapper}>
          <img src={svg} alt={alt} />
        </div>
      </div>
    </div>
  )
}

export default SummaryContainer;