import styles from "../badge.module.scss"
import Badge from "../../BadgeStructor"
import json from "./template.json"

const Template = ({ badge, setBadge }) => {

  return (
    <div >
      {json.map((i) => {
        return (
          <button key={i.name} className={styles.button} onClick={() => setBadge({ ...badge, badgeStructor: { ...badge.badgeStructor, text: { ...badge.badgeStructor.text, template: i.name } } })}>
            <Badge badge={{ ...badge, badgeStructor: { ...badge.badgeStructor, text: { ...badge.badgeStructor.text, template: i.name } } }} />
          </button>)
      })}
    </div>
  )
}
export default Template