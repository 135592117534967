import React, { useState } from "react"
import styles from "../../css/pages/create/wizard/main.module.scss"
import { Preview, WizardController } from "../../components/pages/create"
import { useEffect } from "react"
import axios from "axios"
import {
  useParams
} from "react-router-dom";

const Editor = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false)
  const [badge, setBadge] = useState()
  useEffect(() => {

    const fetchData = async () => {
      await axios.get(`${process.env.REACT_APP_API_URL}/badge/id/${id}`)
        .then(response => {
          setBadge(response.data)
          setLoading(true)
        })
    }

    if (!loading) {
      fetchData()
        .catch(console.error);
    }
  }, [])

  return (
    <>
      {loading &&
        <div className={styles.wrapper}>
          <div className={`${styles.containers} ${styles.cWizard}`}>
            <WizardController badge={badge} setBadge={setBadge} id={id} />
          </div>
          <div className={`${styles.containers} ${styles.cPreview}`}>
            <Preview badge={badge} />
          </div>
        </div>
      }
    </>
  )
}

export default Editor