import styles from "../../css/pages/badges/badge/structor.module.scss"
import {
  useParams
} from "react-router-dom";
import React, { useEffect, useState, useContext } from "react"
import axios from "axios"
import { BadgeCardBig } from "../../components/global/BadgeCards"
import SummaryContainer from "../../components/global/SummaryContainer"
import BadgeOwnerList from "../../components/global/BadgeOwnerList"
import BadgeScrollBar from "../../components/global/BadgeScrollBar"
import { StoreContext } from "../../services/Context"
import { SendBadge } from "./index"

const BadgeView = () => {
  const { id } = useParams();
  const { state: { userData } } = useContext(StoreContext);
  const [badgeCreator, setBadgeCreator] = useState()
  const [badge, setBadge] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const badgeResponse = await axios.get(`${process.env.REACT_APP_API_URL}/badge/id/${id}`)
      if (badgeResponse.status === 200) {
        setBadge(badgeResponse.data)
        const badgeCreatorResponse = await axios.get(`${process.env.REACT_APP_API_URL}/user/${badgeResponse.data.creator}`)
        if (badgeCreatorResponse.status === 200) {
          setBadgeCreator(badgeCreatorResponse.data)
          setLoading(true)
        }
      }
    }
    fetchData()
      .catch(console.error);
  }, [id])

  return (
    loading &&
    < div className={styles.mainWrapper} >
      <div className={styles.leftWrapper}>
        <div className={styles.cardWrapper}>
          <BadgeCardBig
            badge={badge}
            user={userData.userPrincipalName}
          />
        </div>
        <BadgeScrollBar
          title={"The Creator has also created"}
          badges={badgeCreator.badgeCreated} />
      </div>
      <div className={styles.rightWrapper}>
        {userData.userPrincipalName === badge.creator
          ?
          <SendBadge badgeID={id} setBadge={setBadge} badge={badge} />
          :
          <SummaryContainer title={"Total Equiped"} number={badge.badgeCollected.length} svg={"/assets/symbols/handheart.svg"} alt={"badge"} />
        }
        {badge.badgeCollected.length > 0 && <BadgeOwnerList badge={badge} />}
      </div>
    </div >
  )
}

export default BadgeView