import { BadgeCardSmall } from "../../../global/BadgeCards"
import styles from "../../../../css/pages/create/wizard/preview.module.scss"
import CheckBox from "./Checkbox"
import { StoreContext } from "../../../../services/Context"
import { useContext } from "react"

const Preview = ({ badge, setAgree, agree }) => {
  const { state: { userData } } = useContext(StoreContext);

  const downloadSVGAsText = () => {
    const svg = document.getElementById('badgePreviewSVG');
    const base64doc = btoa(unescape(encodeURIComponent(svg.outerHTML)));
    const a = document.createElement('a');
    const e = new MouseEvent('click');
    a.download = 'download.svg';
    a.href = 'data:image/svg+xml;base64,' + base64doc;
    a.dispatchEvent(e);
  }

  return (
    <>
      <h2 className="fSizeB">Badge Preview</h2>
      <div className={styles.previewWrapper}>
        <BadgeCardSmall title={badge.name} creator={"max"} removeBadge={true} badge={badge} />
      </div>
      <h2 className="fSizeB">Badge Information</h2>
      <div className={styles.informationWrapper}>
        <div>
          <h3 className={`${styles.titleWrapper} fSizeM`}>Title</h3>
          <h3 className={`fSizeM`}>{badge.name}</h3>
        </div>
        <div>
          <h3 className={`${styles.titleWrapper} fSizeM`}>Creator</h3>
          <h3 className={`fSizeM`}>{userData.userPrincipalName}</h3>
        </div>
        <div>
          <h3 className={`${styles.titleWrapper} fSizeM`}>Description</h3>

          <h3 style={{ maxHeight: "100px", overflowY: "scroll", marginRight: "10px" }} className={`fSizeM`}>{badge.desc}</h3>
        </div>
        <div>
          <h3 className={`${styles.titleWrapper} fSizeM`}>CDS location</h3>
          <h3 className={`fSizeM`}>{userData.officeLocation}</h3>
        </div>
        <div>
          <h3 className={`${styles.titleWrapper} fSizeM`}>Open/close badge</h3>
          <h3 className={`fSizeM`}>{badge.available}</h3>
        </div>
      </div>
      <div style={{ padding: "1rem 0px" }}>
        {CheckBox({
          desc: "I understand that this badge will be conncted to my work information and the created badge is using a language that goes with the path of the ikea value",
          obj: <input checked={agree}
            onChange={() => {
              setAgree(!agree)

            }}
            style={{ width: "20px", height: "20px" }} type="checkbox" />
        })}
      </div>
    </>
  )

}

export default Preview