const ProfileLogo = ({ c1, c2, c3 }) => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="6.74209" cy="4.27425" rx="3.90323" ry="4.08065" fill={c1} />
      <ellipse cx="6.74194" cy="12.613" rx="6.74194" ry="3.19355" fill={c1} />
    </svg>

  )
}

export default ProfileLogo