import { Slider } from 'primereact/slider';

const TextInput = ({ setBadge, badge }) => {
  return (
    <div style={{ display: "flex" }}>
      <input style={{ flexGrow: 1 }} value={badge.badgeStructor.text.title} onChange={(e) => { setBadge({ ...badge, badgeStructor: { ...badge.badgeStructor, text: { ...badge.badgeStructor.text, title: e.target.value } } }) }}></input>
      <div style={{ flexGrow: 1, margin: "0px 2rem" }}>
        <h3 className="fSizeS">Font Size</h3>
        <Slider value={badge.badgeStructor.text.fontsize} onChange={(e) => setBadge({ ...badge, badgeStructor: { ...badge.badgeStructor, text: { ...badge.badgeStructor.text, fontsize: e.value } } })} min={12} max={52} />
      </div>
    </div>
  )
}

export default TextInput 