import json from "./symbols.json"
import styles from "../badge.module.scss"
import { Heart, Fish, Bike, Egg, Electronic } from "./assets"


const Symbols = ({ setBadge, badge }) => {
  return (
    <div className={styles.wrapper}>
      {
        json.map((i) => {
          return (
            <button key={i.name} className={styles.button} onClick={() => setBadge({ ...badge, badgeStructor: { ...badge.badgeStructor, symbol: i.name } })}>
              {i.name === "Heart" && <Heart color={badge.badgeStructor.colors.symbolColor} />}
              {i.name === "Fish" && <Fish color={badge.badgeStructor.colors.symbolColor} />}
              {i.name === "Bike" && <Bike color={badge.badgeStructor.colors.symbolColor} />}
              {i.name === "Egg" && <Egg color={badge.badgeStructor.colors.symbolColor} />}
              {i.name === "Electronic" && <Electronic color={badge.badgeStructor.colors.symbolColor} />}
            </button>)
        })
      }
    </div>
  )
}

export default Symbols;