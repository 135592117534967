import { useNavigate } from "react-router-dom"
import styles from "../../css/global/component/badgecard.module.scss"
import Badge from "../pages/create/wizard/BadgeStructor.jsx"

const BadgeCardSmall = ({ title, creator, removeBadge, badge }) => {
  return (
    <div className={styles.sMainWrapper}>
      <div className={styles.sContentWraper}>
        <h1 className={`${styles.sTitle} fSizeS`}>{title}</h1>
        <div className={styles.flex}>
          <div className={styles.sProfileWrapper}>
            <img src={"/assets/symbols/profile.svg"} alt={"profile"} />
          </div>
          <h2 className={`${styles.sProfileName} fSizeS fNormalWeight`} >{creator}</h2>
        </div>
        <div className={styles.sButtonWrapper}>
          {!removeBadge &&
            <button className={`${styles.goToBadge} fSizeS`}>
              Go to badge
            </button>
          }
          <button className={styles.shareButton}>
            <img src={"/assets/symbols/share.svg"} alt={"Share"} />
          </button>
        </div>
      </div>
      <div className={styles.sImageWrapper}>
        <div className={styles.sBadgeCircleWrapper}>
          {badge &&
            <>
              {badge.uploadedImage ?
                <img alt="not fount" width={"100%"} src={URL.createObjectURL(badge.uploadedImage)} />
                :
                <Badge badge={badge} />
              }
            </>
          }
        </div>
      </div>
    </div>
  )
}

const BadgeCardBig = ({ badge, user }) => {
  const navigation = useNavigate()
  const youOwnBadge = badge.creator === user

  const toPath = (path) => {
    navigation(path)
  }

  return (
    <div className={styles.bMainWrapper}>
      <div className={styles.bImageWrapper} >
        <Badge badge={badge} />
      </div >
      <div className={styles.bContentWraper}>
        <h1 className={`${styles.bTitle} fSizeB`}>{badge.name}</h1>
        <p className={`${styles.bDesc} fSizeSM`}>{badge.desc}</p>
        <div style={{ marginTop: "auto" }}>
          <div style={{ flexDirection: "column" }}>
            <h2 className={`${styles.bInfoWrapper} fSizeSM fNormalWeight`} ><span style={{ fontWeight: "bold" }}>Created by: </span>{badge.creator}</h2>
            <h2 className={`${styles.bInfoWrapper} fSizeSM fNormalWeight`} ><span style={{ fontWeight: "bold" }}>Created at: </span>{badge.created}</h2>
          </div>
        </div>
        <div className={styles.bButtonWrapper}>
          {youOwnBadge &&
            <div style={{ display: "flex", gap: "10px" }}>
              <button onClick={() => toPath(`/build/edit/${badge.id}`)} className={`${styles.editBadge} fSizeS`}>
                <h1 className={`${styles.buttonTitle} fSizeM`}>Edit Badge</h1>
                <img src={"/assets/symbols/pencil.svg"} alt={"Pencil"} />
              </button>
            </div>}
          <button className={`${styles.certification} fSizeS`} onClick={() => toPath(`/certificate/${badge.id}`)}>
            <span style={{ margin: "auto 0px", fontWeight: "bold" }}>Preview Certificate</span>
            <img src={"/assets/symbols/certification.svg"} alt={"Certification"} />
          </button>
        </div>
      </div>

    </div >
  )
}

export { BadgeCardSmall, BadgeCardBig }