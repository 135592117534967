import React, { useState, useContext } from "react"
import CheckBox from "./Checkbox"
import { StoreContext } from "../../../../services/Context"

const Information = ({ setBadge, badge }) => {
  const [toggle, setToggle] = useState()
  const { state: { userData } } = useContext(StoreContext);

  console.log(userData)
  return (
    <>
      <h2 className="fSizeB">Badge name</h2>
      <input value={badge.name} onChange={(e) => { setBadge({ ...badge, name: e.target.value }) }} />
      <h2 className="fSizeB">Description</h2>
      <textarea style={{ resize: "vertical", maxHeight: "8rem", height: "5rem", minHeight: "5rem" }} value={badge.desc} onChange={(e) => { setBadge({ ...badge, desc: e.target.value }) }} />
      <h2 className="fSizeB">Closed/Open</h2>
      <div style={{ display: "flex" }}>
        {CheckBox({
          title: "Closed badge",
          desc: "By clicking the checkbox, this badge can only be given out from the creator.",
          obj: <input checked={badge.available !== "closed" ? false : true}
            onChange={() => {
              setBadge({
                ...badge,
                available: toggle ? "open" : "closed"
              })
            }}
            style={{ width: "20px", height: "20px" }} type="checkbox" />
        })}
        {CheckBox({
          title: "Open badge",
          desc: "By clicking the checkbox, this badge can be given out from anyone.",
          obj: <input checked={badge.available !== "open" ? false : true}
            onChange={() => {
              setBadge({
                ...badge,
                available: toggle ? "closed" : "open"
              })
            }}
            style={{ width: "20px", height: "20px" }} type="checkbox" />
        })}
      </div>
      <h2 className="fSizeB">Creator information</h2>
      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "1rem", margin: "0.5rem 0px" }}>
        <div style={{ margin: "0rem 1rem", background: "#f8f8f8", padding: "0.5rem 0.5rem" }} className="fSizeM" >{userData.userPrincipalName}</div>
        <div style={{ margin: "0rem 1rem", background: "#f8f8f8", padding: "0.5rem 0.5rem" }} className="fSizeM" >{userData.officeLocation}</div>
      </div>
    </>
  )

}

export default Information


