import React, { useEffect } from 'react';

import { MsalProvider, useMsal } from '@azure/msal-react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from '@azure/msal-react';
import { msalInstance } from './msal'

const SignIn = () => {
  const { instance, accounts, inProgress } = useMsal();
  useEffect(() => {
    if (instance.getAllAccounts().length === 0 && inProgress === 'none') {
      instance.loginRedirect();
    }
  }, [instance, accounts, inProgress]);
  return null;
}

const Authentication = ({ children }) => {
  return (
    <MsalProvider instance={msalInstance}>
      <SignIn />
      <AuthenticatedTemplate>
        {children}
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <div>Loading..</div>
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
};

export default Authentication;