import styles from "../../css/global/component/badgeownerlist.module.scss"
import { useNavigate } from "react-router-dom"
const BadgeOwnerList = ({ badge }) => {
  const navigation = useNavigate()

  const toPath = (path) => {
    navigation(path)
  }

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.wrapper}>
        <h1 className={`${styles.title} fSizeB fNormalWeight`}>BadgeOwnerList</h1>
        <ul className={styles.listWrapper}>
          {badge.badgeCollected.map((user, index) => {
            return (
              <li onClick={() => toPath(`/certificate/${badge.id}/${user.user}`)} key={"list_item_" + index}>
                <img className={styles.profile} src={"/assets/symbols/profile.svg"} alt={"profile"} />
                {user.user}
                <button className={`${styles.arrowButton} fSizeM`}>&rarr;</button>
              </li>)
          })}
        </ul>
      </div>
    </div>
  )

}

export default BadgeOwnerList